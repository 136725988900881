import React from 'react';
import CreatedBooking from '../../../types/CreatedBooking';
import CreatedBookingItem from './CreatedBookingItem';

interface CreatedBookingItemListProps {
  bookings: CreatedBooking[];
  onCancelBooking: Function;
}

const CreatedBookingItemList : React.FC<CreatedBookingItemListProps> = ({ bookings, onCancelBooking }) => {
  return (
    <div data-testid="booking-references" className="overflow-y-auto bg-white rounded-lg shadow-lg p-4">
      <h2 className="text-xl font-bold mb-2">Created Bookings</h2>
      <ul className="list-inside text-left flex-grow">
        {bookings.map((booking, index) => (
          <CreatedBookingItem 
            key={index}
            reference={booking.reference}
            cancelled={booking.cancelled}
            onCancelBooking={onCancelBooking}
             />
        ))}
      </ul>
    </div>
  );
};

export default CreatedBookingItemList;