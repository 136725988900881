import React from 'react';
import { NavLink } from 'react-router-dom';
interface HeaderBarProps {
  environment: string;
}

const HeaderBar : React.FC<HeaderBarProps> = ({ environment }) => {
  return (
    <div id="header" className="flex flex-nowrap justify-between col-span-full py-2 bg-green-500 text-white pl-4 p-2 shadow-lg rounded-xl">
      <h1 className="text-2xl leading-9 inline-block me-4 object-right">Test Setup: {environment}</h1>
      <nav className="inline-block bg-white p-2 rounded-md flex">
        <ul>
          <li className="inline-block">
            <NavLink to="/CreateBookings" className={({ isActive }) => (`text-slate-950 px-3 py-1 me-2 rounded-sm font-bold ${isActive ? "bg-slate-200" : "text-slate-500" }`)}>Create Bookings</NavLink>
          </li>
          <li className="inline-block">
            <NavLink to="/FillFlight" className={({ isActive }) => (`text-slate-950 px-3 py-1 rounded-sm font-bold ${isActive ? "bg-slate-200" : "text-slate-500"}`)}>Fill Flights</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HeaderBar;
