import { TextField } from "@mui/material";
import { changeTextFieldNumberValue } from "../../../globalFunctions/utils";
import useFlightRequestStore from "../../../stores/FlightRequestStore";
import PassengerFlightCreationItem from "./PassengerFlightCreationItem";
import FillFlightGroup from "../../../types/fillFlightRequest/FillFlightGroup";
import { getTotalPassengers } from "../../../globalFunctions/FillFlightRequestFunctions";

interface total {
    total: number
}

interface passengerFlightCreationParams {
    values: Array<total>
}

const PassengerFlightCreation: React.FC = () => {
    const { fillFlightRequest, removePassengerGroup, modifyPassengerGroup, changeTotal } = useFlightRequestStore();
    return (
        <div className="w-96">
            <div className="grid grid-cols-[1fr,1fr,1fr,1fr,36px,36px] gap-4 gap-x-2">
                <p className="font-bold">Bookings</p>
                <p className="font-bold">Adults</p>
                <p className="font-bold">Children</p>
                <p className="font-bold">Infants</p>
                <p className="font-bold col-span-2">Total</p>
                {fillFlightRequest.groups.map((item, index) => (
                    <PassengerFlightCreationItem key={index} fillFlightGroup={item} addMethod={(newGroup: FillFlightGroup) => modifyPassengerGroup(index, newGroup)} removeMethod={() => removePassengerGroup(index)} ></PassengerFlightCreationItem>
                ))}
                <p className="text-base leading-10 text-right pe-7">{fillFlightRequest.totalPassengers - fillFlightRequest.groups.reduce((a, b) => a + (getTotalPassengers(b)),0)}</p>
                <p className="text-base leading-10 text-right pe-7">1</p>
                <p className="text-base leading-10 text-right pe-7">0</p>
                <p className="text-base leading-10 text-right pe-7">0</p>
                <p className="text-base leading-10 text-right">{fillFlightRequest.totalPassengers - fillFlightRequest.groups.reduce((a, b) => a + (getTotalPassengers(b)),0)}</p>
                {/* <p className="col-span-3 font-bold">Total single adult bookings:</p>
                <p className="col-span-2">{fillFlightRequest.totalPassengers - fillFlightRequest.groups.reduce((a, b) => a + ((b.Adults + b.Children) * b.Total),0)}</p> */}

                <p className="col-span-4 font-bold pl-3">Total:</p>
                <TextField 
                    className="col-span-2"
                    type="number"
                    size="small"
                    onChange={changeTextFieldNumberValue(changeTotal)}
                    value={fillFlightRequest.totalPassengers}
                    placeholder="total"
                    slotProps={{
                        htmlInput: {
                            min: fillFlightRequest.groups.reduce((a, b) => a + (getTotalPassengers(b)),0)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default PassengerFlightCreation;