import { TextField } from "@mui/material";
import { Trash2 } from "react-feather";
import { changeTextFieldNumberValue } from "../../../globalFunctions/utils";
import FillFlightSsr from "../../../types/fillFlightRequest/FillFlightSsr";

interface SsrFlightCreationItemProps {
    fillFlightGroup: FillFlightSsr,
    removeMethod: () => void;
    addMethod: (ssr: FillFlightSsr) => void;
}

const SsrFlightCreationItem: React.FC<SsrFlightCreationItemProps> = ({fillFlightGroup, removeMethod, addMethod}) => {
    const modifyCode = (code: string) => {
        addMethod({
            Code: code,
            Total: fillFlightGroup.Total
        })
    }

    const modifyTotal = (total: number) => {
        addMethod({
            Code: fillFlightGroup.Code,
            Total: total
        })
    }

    return (
        <>
            <TextField 
                className=""
                onChange={(value) => modifyCode(value.target.value)}
                size="small"
                value={fillFlightGroup.Code}
                placeholder="ssr"
            />
            <div></div>
            <TextField 
                className=""
                type="number"
                onChange={changeTextFieldNumberValue(modifyTotal)}
                size="small"
                placeholder="total"
                slotProps={{
                    htmlInput: {
                        min: 0
                    }
                }}
                value={fillFlightGroup.Total}
            />
            <button 
                type="button"
                onClick={(e) =>{
                    e.preventDefault();
                    removeMethod();
                }} 
                    className='w-min h-max pl-1'>
                <Trash2 width={32} height={32} className='p-2 m-1 rounded text-gray-600 hover:text-red-900 hover:bg-red-100' />
            </button>
        </>
    )
}

export default SsrFlightCreationItem;