import React from "react";

interface FlightCardProps {
  flightNumber: string;
  date: string;
  route: string;
  bookings: number;
  bookingsCompleted: number;
  onClick: Function;
  chosen: boolean;
}

const FlightCard: React.FC<FlightCardProps> = ({
  flightNumber,
  date,
  route,
  bookings,
  bookingsCompleted,
  onClick,
  chosen
}) => {
  return (
    <div
      className={`flex flex-1 items-center justify-between py-1 px-4 rounded-lg shadow-md ${
        chosen ? "bg-green-600" : "bg-gray-100 hover:bg-gray-300 "
      }`}
      onClick={() => onClick()}
    >
      <div className="flex flex-col">
        <p className={`text-xl font-bold  ${ chosen ? "text-white" : "text-gray-800"}`}>
          {flightNumber}
        </p>
        <p className={`font-bold text-sm ${ chosen ? "text-green-300" : "text-gray-600"}`}>
          {route}
        </p>
      </div>

      <div className="flex flex-col items-end">
        <p className={`${ chosen ? "text-green-100" : "text-gray-800"}`}>
          {date}
        </p>
        <p className={`text-xs pt-2 ${ chosen ? "text-green-100" : "text-gray-600"}`}>
          {bookingsCompleted}/{bookings} Bookings Created
        </p>
      </div>
    </div>
  );
};

export default FlightCard;