import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import FillFlightRequest from '../types/fillFlightRequest/FillFlightRequest';
import FillFlightSsr from '../types/fillFlightRequest/FillFlightSsr';
import FillFlightGroup from '../types/fillFlightRequest/FillFlightGroup';
import FillFlightFareType from '../types/fillFlightRequest/FillFlightFares';

interface FillFlightRequestState {
    fillFlightRequest: FillFlightRequest;
    set: (flightId: FillFlightRequest) => void;
    setFlightId: (flightId: string) => void;
    addPassengerGroup: () => void;
    addSsr: () => void;
    removeSsr: (index: number) => void;
    removePassengerGroup: (index: number) => void;
    modifySsr: (index: number, newSsr: FillFlightSsr) => void;
    modifyPassengerGroup: (index: number, newPassengerGroup: FillFlightGroup) => void;
    modifyFareType: (fareType: FillFlightFareType) => void;
    changeTotal: (newTotal: number) => void;
    reset: () => void;
}

const defaultFillFlightRequest: FillFlightRequest = 
{
        totalPassengers: 80,
        flightId: "", 
        fareTypes: [
            { 
                FareType:"MAX", 
                Total: 20 
            },
            { 
                FareType:"PLUS", 
                Total: 10 
            }
        ], 
        ssrs: [
            {
                Code: "5B15",
                Total: 15
            },
            {
                Code: "5B20",
                Total: 5
            },
            {
                Code: "5B25",
                Total: 5
            },
            {
                Code: "AVIH",
                Total: 1
            },
            {
                Code: "PETC",
                Total: 1
            },
            {
                Code: "CLUG",
                Total: 20
            },
            {
                Code: "WCHC",
                Total: 1
            },
            {
                Code: "WCHR",
                Total: 1
            },
            {
                Code: "BIKE",
                Total: 1
            },
            {
                Code: "DEAF",
                Total: 1
            },
            {
                Code: "SURF",
                Total: 1
            }
        ], 
        groups: [
            {
                Adults: 2, 
                Children: 2,
                Infants: 0,
                Total: 10
            },
            {
                Adults: 3, 
                Children: 0,
                Infants: 0,
                Total: 3
            },
            {
                Adults: 2, 
                Children: 0,
                Infants: 0,
                Total: 8
            }
        ]
    }

const useFlightRequestStore = create<FillFlightRequestState>()(persist((set) => ({
    fillFlightRequest: defaultFillFlightRequest,
    reset: () => set((state) => ({
        fillFlightRequest: {
            ...defaultFillFlightRequest,
            flightId: state.fillFlightRequest.flightId
        }
    })),
    changeTotal: (newTotal: number) => set((state) => ({
        fillFlightRequest: {
            ...state.fillFlightRequest,
            totalPassengers: newTotal
        }
    })), 
    removePassengerGroup: (index: number) => set((state) => ({
        fillFlightRequest: {
          ...state.fillFlightRequest,
          groups: state.fillFlightRequest.groups.filter((_, i) => i !== index)
        },
    })),  
    modifyFareType: (fareType: FillFlightFareType) => set((state) => {
        const fares = [...state.fillFlightRequest.fareTypes.filter(x => x.FareType !== fareType.FareType), fareType]
        return ({
            fillFlightRequest: {
                ...state.fillFlightRequest,
                fareTypes: fares
            }
        })
    }),
    modifySsr: (index: number, newSsr: FillFlightSsr) => set((state) => {
        const ssrs = [...state.fillFlightRequest.ssrs]
        ssrs[index] = newSsr
        return ({
            fillFlightRequest: {
                ...state.fillFlightRequest,
                ssrs: ssrs
            }
        })
    }),   
    modifyPassengerGroup: (index: number, newGroup: FillFlightGroup) => set((state) => {
        const groups = [...state.fillFlightRequest.groups]
        groups[index] = newGroup
        return ({
            fillFlightRequest: {
                ...state.fillFlightRequest,
                groups: groups
            }
        })
    }),
    removeSsr: (index: number) => set((state) => ({
        fillFlightRequest: {
          ...state.fillFlightRequest,
          ssrs: state.fillFlightRequest.ssrs.filter((_, i) => i !== index)
        },
    })),  
    addSsr: () => set((state) => ({
        fillFlightRequest: {
            ...state.fillFlightRequest,
            ssrs: [
                ...state.fillFlightRequest.ssrs,
                {
                    Code: "5B20",
                    Total: 1
                }
            ]
        }
    })),
    addPassengerGroup: () => set((state) => ({
        fillFlightRequest: {
            ...state.fillFlightRequest,
            groups: [
                ...state.fillFlightRequest.groups,
                {
                    Adults: 1,
                    Children: 0,
                    Infants: 0,
                    Total: 1
                }
            ]
        }
    })),
    set: (fillFlightRequest: FillFlightRequest) => set((state) => ({fillFlightRequest: fillFlightRequest})),
    setFlightId: (flightId: string) => set((state) => ({fillFlightRequest: {
        ...state.fillFlightRequest,
        flightId: flightId
    }}))
    }), 
    {
        name: "fill-flight-request"
    }));

export default useFlightRequestStore;