import useFlightRequestStore from "../../../stores/FlightRequestStore";
import FillFlightSsr from "../../../types/fillFlightRequest/FillFlightSsr";
import SsrFlightCreationItem from "./SsrFlightCreationItem";

interface total {
    total: number
}

interface SsrFlightCreationParams {
    values: Array<total>
}

const SsrFlightCreation: React.FC = () => {
    const { fillFlightRequest, removeSsr, modifySsr } = useFlightRequestStore();

    return (
        <div className="grid grid-cols-[90px,auto,80px,40px] gap-y-4">    
            <p className="font-bold flex-1 col-span-2">SSR</p>
            <p className="font-bold flex-1 col-span-2">AMOUNT</p>
            {fillFlightRequest.ssrs.map((item, index) => (
                <SsrFlightCreationItem key={index} fillFlightGroup={item} removeMethod={() => removeSsr(index)} addMethod={(value: FillFlightSsr) => modifySsr(index, value)} />
            ))}
        </div>
    )
}

export default SsrFlightCreation;