import { TextField } from "@mui/material";
import { Trash2 } from "react-feather";
import { changeTextFieldNumberValue } from "../../../globalFunctions/utils";
import useFlightRequestStore from "../../../stores/FlightRequestStore";
import FillFlightGroup from "../../../types/fillFlightRequest/FillFlightGroup";
import { getTotalPassengers } from "../../../globalFunctions/FillFlightRequestFunctions";

interface PassengerFlightCreationItemProps {
    fillFlightGroup: FillFlightGroup,
    removeMethod: () => void;
    addMethod: (newPassengerGroup: FillFlightGroup) => void;
}

const PassengerFlightCreationItem: React.FC<PassengerFlightCreationItemProps> = ({fillFlightGroup, removeMethod, addMethod}) => {
    const { fillFlightRequest } = useFlightRequestStore();

    const changeAdults = (adults: number) => {
        addMethod({
            Adults: adults,
            Children: fillFlightGroup.Children,
            Infants: fillFlightGroup.Infants,
            Total: fillFlightGroup.Total
        })
    }

    const changeChildren = (children: number) => {
        addMethod({
            Adults: fillFlightGroup.Adults,
            Children: children,
            Infants: fillFlightGroup.Infants,
            Total: fillFlightGroup.Total
        })
    }

    const changeInfants = (infants: number) => {
        addMethod({
            Adults: fillFlightGroup.Adults,
            Children: fillFlightGroup.Children,
            Infants: infants,
            Total: fillFlightGroup.Total,
        })
    }

    const changeTotal = (total: number) => {
        addMethod({
            Adults: fillFlightGroup.Adults,
            Children: fillFlightGroup.Children,
            Infants: fillFlightGroup.Infants,
            Total: total
        })
    }

    return (
        <>
            <TextField 
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(changeTotal)}
                placeholder="number of groups"
                value={fillFlightGroup.Total}
                slotProps={{
                    htmlInput: {
                        min: 0
                    }
                }}
            />
            <TextField
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(changeAdults)}
                value={fillFlightGroup.Adults}
                placeholder="adults"
                slotProps={{
                    htmlInput: {
                        min: 0,
                        max: 20
                    }
                }}
            />
            <TextField
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(changeChildren)}
                placeholder="children"
                value={fillFlightGroup.Children}
                slotProps={{
                    htmlInput: {
                        min: 0,
                        max: 20
                    }
                }}
            />
            <TextField
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(changeInfants)}
                placeholder="infants"
                value={fillFlightGroup.Infants}
                slotProps={{
                    htmlInput: {
                        min: 0,
                        max: fillFlightGroup.Adults
                    }
                }}
            />
            <p className="w-full text-base leading-10 text-right">{getTotalPassengers(fillFlightGroup)}</p>
            <button 
                type="button"
                onClick={(e) =>{
                    e.preventDefault();
                    removeMethod();
                }} 
                    className='w-min h-min m'>
                <Trash2 width={32} height={32} className='p-2 m-1 rounded text-gray-600 hover:text-red-900 hover:bg-red-100' />
 
            </button>
        </>
    )
}

export default PassengerFlightCreationItem;