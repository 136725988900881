import useFillFlightStore from "../stores/fillFlightStore";
import CreateBookingParameters from "../types/createBookingRequest/CreateBookingParameters";
import CreateBookingStatus from "../types/CreateBookingStatus";
import CreatedBooking from "../types/CreatedBooking";
import fillFligtRequest from "../types/fillFlightRequest/FillFlightRequest";
import BookingService from "./bookingService";
import FillFlightDataService from "./FillFlightDataService";
import { toFlight } from "../globalFunctions/FlightId";

const fillFlightService = {
    giveMeSomeRandomBookingParam(flightId: string): CreateBookingParameters {
        var flight = toFlight(flightId)
        const newParams: CreateBookingParameters = {
            ContactDetails: null,
            FlightOptions: [{DepartureDate: flight.DepartureDate, Origin: flight.Origin, CarrierCode: flight.CarrierCode, Destination: flight.Destination, FlightNumber: flight.FlightNumber, IncludeReturnFlight: null, Ssrs: []}],
            PassengerDetails: [],
            PassengerTypes: {
                Adults: 1,
                Children: 0,
                InfantsOnSeat: 0
            },
            ProductClass: "basic",
            ThirdPartyRecordLocator: {
                RecordCode: "",
                OwningSystemCode: ""
            }
        } 
        return newParams;
    },
    
    validateRequest(fillFlightRequest: fillFligtRequest) {
        return FillFlightDataService.validation(fillFlightRequest, fillFlightRequest.totalPassengers);
    },

    async fillFlight(fillFlightRequest: fillFligtRequest, isProcessing: (isProcessing: boolean) => void) {
        const fillFlightState = useFillFlightStore.getState()
        if (!fillFlightState.fillFlightRequests.hasOwnProperty(fillFlightRequest.flightId)) {
            fillFlightState.put(fillFlightRequest.flightId, {})
        }
        
        if (FillFlightDataService.validation(fillFlightRequest, fillFlightRequest.totalPassengers).length !== 0) throw new Error('Not a valid request'); 

        var bookingRequests = FillFlightDataService.convertFillFlightRequestToBookingRequests(fillFlightRequest, fillFlightRequest.totalPassengers);

        const currMax = Math.max(-1, ...Object.keys(fillFlightState.fillFlightRequests[fillFlightRequest.flightId] ?? []).map(Number));
        bookingRequests.forEach((booking, id) => {
            const bookingStatus: CreateBookingStatus = {
                "booking": booking,
                "result": null
            }
    
            fillFlightState.updateStatus(fillFlightRequest.flightId, currMax + id + 1, bookingStatus);
        })
        
        isProcessing(true)
        await this.manyBookingsProcessor(fillFlightRequest.flightId, bookingRequests);
        isProcessing(false)
    },


    async manyBookingsProcessor(flightNumber: string, bookings: CreateBookingParameters[]): Promise<string[]> {
        var bookingReferences: string[] = []

        // zero for now :( could ofcourse be changed again if we find some way to create an arbitrary amount of bookings in parralel on a single flight
        // we get back a 500 now from digital api when trying but mayby uat will solve hopefully
        var rateOfChange = 0;
        var dRRatio = 1;
        var dTRatio = 1;
        var previousResponseTime = 10 * 1000;
        var numberOfRequests = 1;

        var maxResponseTime = 25 * 1000;

        var fillFlightState = useFillFlightStore.getState()

        while (Object.entries(fillFlightState.fillFlightRequests[flightNumber]).some(x => x[1].failed !== true && x[1].result === null)) {
            fillFlightState = useFillFlightStore.getState()
            var promisses: Promise<string>[] = []
            var bookingsToBeProcessed = Object.entries(fillFlightState.fillFlightRequests[flightNumber]).filter(x =>  x[1].failed !== true && x[1].result === null)
            for (var k = 0; k < numberOfRequests && k < bookingsToBeProcessed.length; k++) {
                promisses.push(this.bookingProcessor(flightNumber, Number(bookingsToBeProcessed[k][0]), bookingsToBeProcessed[k][1].booking));
            }

            var startTime = performance.now();            
            
            var newBookingReferences = await Promise.all(promisses);
            bookingReferences = bookingReferences.concat(newBookingReferences);
            var currResponseTime = performance.now() - startTime;
            
            // dynamically change the number off requests that are send to make sure not to overwelm the backend
            if (currResponseTime < maxResponseTime) {
                dTRatio = currResponseTime / previousResponseTime;
                var change = dRRatio / dTRatio;
                
                var addChangeForNumberOfRequests = Math.ceil(((change * numberOfRequests) - numberOfRequests) * rateOfChange);

                dRRatio = (addChangeForNumberOfRequests + numberOfRequests) / numberOfRequests;

                numberOfRequests += addChangeForNumberOfRequests;

                if (numberOfRequests < 1) numberOfRequests = 1;
            }

            previousResponseTime = currResponseTime;
        }
        
        return bookingReferences;
    },
    async retryPolicy(asyncFunction: (error: string | null) => Promise<any>, totalTries: number, error: string | null = null): Promise<any> {
        try {
            return await asyncFunction(error)
        } catch (err) {
            if (totalTries > 1) {
                console.log(`request failed trying again`)
                return await this.retryPolicy(asyncFunction, totalTries - 1, `${err}`);
            }
            console.log(`request failed with following error: ${err}`)
            throw err;
        }
    },
    convertParameters(err: string | null, bookingParams: CreateBookingParameters): CreateBookingParameters {
        if (err === null) return bookingParams;
        const ssrRegex = [/Not enough availability to add ssr of type (\w+)/, /Could not find ssr (\w+)/];
        
        const matches = ssrRegex.map(x => err.match(x)).filter(x => x != null);

        matches.forEach(match => {
            const extractedType = match![1];
            for (var i = 0; i < bookingParams.FlightOptions.length; i++) {
                bookingParams.FlightOptions[i].Ssrs = bookingParams.FlightOptions[i].Ssrs.filter(x => x.ssrCode !== extractedType);
            }
        })

        return bookingParams;
    },

    async bookingProcessor(flightNumber: string, id: number, booking: CreateBookingParameters): Promise<string> {    
        try {   
            var newCreatedBooking = await this.retryPolicy((err: string | null) => {
                const params = this.convertParameters(err, booking);
                
                return BookingService.createBookingRequest(params, true)
            } , 3);

            // update the fill flight store
            const bookingResult: CreatedBooking = {
                reference: newCreatedBooking.bookingReference,
                cancelled: false
            }

            const newBookingStatus: CreateBookingStatus = {
                "booking": booking,
                "result": bookingResult
            }

            this.updateFillFlightStatus(flightNumber, id, newBookingStatus)

            return bookingResult.reference;
        } catch (Error) {
            const newBookingStatus: CreateBookingStatus = {
                "booking": booking,
                "result": null,
                "failed": true
            }

            this.updateFillFlightStatus(flightNumber, id, newBookingStatus);
            return "";
        }
    },

    updateFillFlightStatus(flightNumber: string, id: number, newBookingStatus: CreateBookingStatus) {
        const fillFlightState = useFillFlightStore.getState()

        fillFlightState.updateStatus(flightNumber, id, newBookingStatus);
    }
    
}

export default fillFlightService;