import CreateBookingParameters from "../types/createBookingRequest/CreateBookingParameters";
import fillFligtRequest from "../types/fillFlightRequest/FillFlightRequest";
import {shuffle} from "../globalFunctions/utils";
import { toFlight } from "../globalFunctions/FlightId";
import Ssr from "../types/createBookingRequest/Ssr";
import FillFlightGroup from "../types/fillFlightRequest/FillFlightGroup";
import FillFlightFareType from "../types/fillFlightRequest/FillFlightFares";
import FillFlightSsr from "../types/fillFlightRequest/FillFlightSsr";
import { getTotalPassengers } from "../globalFunctions/FillFlightRequestFunctions";

enum PassengerType {
    InfantOnSeat = 'InfantOnSeat',
    Child = 'Child',
    Adult = 'Adult'
}

interface PassengerSsr {
    PassengerType: PassengerType
    Ssr: string | null
}

interface Group {
    Passengers: Array<PassengerSsr>
    ProductClass: string
}

const FillFlightDataService = {
    // take a look at this again since it is shit :) (I don't know)
    convertFillFlightRequestToBookingRequests(fillFlightRequest: fillFligtRequest, totalSpacesToFill: number): Array<CreateBookingParameters> {
        var allSsrs: PassengerSsr[] = this.convertSsrRequestsToSingleSsrs(fillFlightRequest.ssrs);

        // fill additional spaces with empty ssrs
        var totalInfants = fillFlightRequest.groups.reduce((a, b) => a + (b.Infants * b.Total), 0)
        var additionalSpacesToFill = totalSpacesToFill - allSsrs.length - totalInfants;
        for (var i = 0; i < additionalSpacesToFill; i++) {
            allSsrs.push({
                Ssr: null, 
                PassengerType: PassengerType.Adult
            })
        }

        // shuffle spaces for random ssrs to different groups
        shuffle(allSsrs);

        var groups: Array<Group> = this.convertSsrAndPassengersToGroups(allSsrs, fillFlightRequest.groups);

        // shuffle groups for random fare types to random groups
        shuffle(groups);

        var k = 0;
        for (const fareType of fillFlightRequest.fareTypes) {
            for (var i = 0; i < fareType.Total; i++) {
                groups[k].ProductClass = fareType.FareType;
                k++;
            }
        }

        return this.convertGroupsToBookingParameters(groups, fillFlightRequest.flightId);
    },

    addFareTypesToGroups(groups: Group[], fareTypes: FillFlightFareType[]) {
        var currIndex = 0;
        fareTypes.forEach(x => {
            for (var i = 0; i < x.Total; i++) {
                groups[currIndex].ProductClass = x.FareType
                currIndex++;   
            }
        })
    },

    convertSsrAndPassengersToGroups(passengerSsrs: PassengerSsr[], groupRequests: FillFlightGroup[]): Array<Group> {
        var groups: Array<Group> = [];
        
        var currPassengerSsrPointer= 0;
        var currPassengerGroupPointer= 0;
        while (currPassengerSsrPointer< passengerSsrs.length) {
            var newGroup: Array<PassengerSsr> = []
            if (currPassengerGroupPointer< groupRequests.length) {
                for (var n = 0; n < groupRequests[currPassengerGroupPointer].Total; n++) {
                    newGroup = []

                    for (var pointer = currPassengerSsrPointer; pointer < groupRequests[currPassengerGroupPointer].Adults + currPassengerSsrPointer+ groupRequests[currPassengerGroupPointer].Children; pointer++) {
                        if (pointer >= currPassengerSsrPointer+ groupRequests[currPassengerGroupPointer].Adults) {
                            passengerSsrs[pointer].PassengerType = PassengerType.Child
                        }
                        newGroup.push(passengerSsrs[pointer])
                    }

                    for (var i = 0; i < groupRequests[currPassengerGroupPointer].Infants; i++) {
                        newGroup.push({
                            Ssr: null, 
                            PassengerType: PassengerType.InfantOnSeat
                        });
                    }
                    
                    groups.push({
                        Passengers: newGroup,
                        ProductClass: "basic"
                    })

                    currPassengerSsrPointer+= groupRequests[currPassengerGroupPointer].Adults + groupRequests[currPassengerGroupPointer].Children;
                }
                currPassengerGroupPointer++;
            } else {
                newGroup.push(passengerSsrs[currPassengerSsrPointer]);
                currPassengerSsrPointer++;

                groups.push({
                    Passengers: newGroup,
                    ProductClass: "basic"
                })
            }
        }

        return groups;
    },

    // fill flight ssrs should be converted to individual ssrs
    convertSsrRequestsToSingleSsrs(flightSsrRequests: FillFlightSsr[]): PassengerSsr[] {
        var allSsrs: PassengerSsr[] = []

        //first convert all the ssr requests to single ssrs
        for (var i = 0; i < flightSsrRequests.length; i++) {
            const currSsr = flightSsrRequests[i]

            for (var j = 0; j < currSsr.Total; j++) {
                allSsrs.push({
                    Ssr: currSsr.Code, 
                    PassengerType: PassengerType.Adult
                })
            }
            allSsrs = allSsrs;
        }

        return allSsrs;
    },

    // validate fillFlightRequest:
    // there should not be more ssr requests then passengers
    // there should not be more fare types then groups
    // there should not be more passengers in groups then total passengers
    validation(fillFlightRequest: fillFligtRequest, totalSpacesToFill: number): string[] {
        const allErrors: string[] = []
        var totalPassengersInGroups = fillFlightRequest.groups.reduce((a, b): number => a + getTotalPassengers(b), 0)
        var totalGroups = totalSpacesToFill - totalPassengersInGroups + fillFlightRequest.groups.reduce((a, b) => a + b.Total, 0);
        if (fillFlightRequest.fareTypes.reduce((a, b) => a + b.Total, 0) > totalGroups) allErrors.push(`number of fare types cannot exceed number of bookings`);

        if (fillFlightRequest.ssrs.reduce((a, b) => a + b.Total, 0) > totalSpacesToFill) allErrors.push("total number of ssrs cannot exceed number of passengers"); 

        if (fillFlightRequest.groups.reduce((a, b) => a + getTotalPassengers(b), 0) > totalSpacesToFill) allErrors.push("total number of groups cannot be larger then total passengers");
        
        return allErrors;
    },

    convertGroupsToBookingParameters(groups: Array<Group>, flightId: string): Array<CreateBookingParameters> {

        // helper function
        function CreateSsrs(passengers: Array<PassengerSsr>, shouldBePassengerType: PassengerType, addIndex: number): Ssr[] {
            return passengers
                .filter(x => x.PassengerType == shouldBePassengerType)
                .map((x, index) => {
                    if (x.Ssr == null) return null;
                    return {
                        ssrCode: x.Ssr,
                        passengerIndex: index + addIndex
                    }
                })
                .filter(x => x != null) as Ssr[];
        }

        return groups.map(x => {
            var flight = toFlight(flightId);

            var totalPassengersOfType = function(passengerType: PassengerType): number {
                return x.Passengers.reduce((a, b) => {
                    if (b.PassengerType === passengerType) return a + 1
                    return a
                }, 0)


            }
            
            var adultPassengers = totalPassengersOfType(PassengerType.Adult);

            var childPassengers = totalPassengersOfType(PassengerType.Child);

            var InfantsOnSeatPassengers = totalPassengersOfType(PassengerType.InfantOnSeat);

            var adultsSsrs: Array<Ssr> = CreateSsrs(x.Passengers, PassengerType.Adult, 0);

            var childrenSsrs: Array<Ssr> = CreateSsrs(x.Passengers, PassengerType.Child, adultPassengers)

            flight.Ssrs = adultsSsrs.concat(childrenSsrs);
            return {
                ContactDetails: null,
                FlightOptions: [flight],
                PassengerDetails: [],
                PassengerTypes: {
                    Adults: adultPassengers,
                    Children: childPassengers,
                    InfantsOnSeat: InfantsOnSeatPassengers
                },
                ProductClass: x.ProductClass,
                ThirdPartyRecordLocator: {
                    RecordCode: "",
                    OwningSystemCode: ""
                }
            }
        })
    }
}

export default FillFlightDataService;