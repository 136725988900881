import React from 'react';
import { Plus } from 'react-feather';

interface DetailsPanelProps {
  title: string;
  addFunction?: Function | null;
  children: React.ReactNode;
}

const DetailsPanel : React.FC<DetailsPanelProps> = ({ title, addFunction, children }) => {
  let dataId = `add-${title}Options-button`;
  return (
    <div className='flex-none flex-0 w-fit min-w-80 overflow-y-auto bg-white rounded-lg p-4 shadow-lg' data-testid={title}>
      <div className='flex justify-between border-b-2 mb-2'>
        <h3 className='mb-4'>{title}</h3>
        { addFunction && (
          <button 
            data-testid={dataId}
            className="mb-4"
            type="button"
            onClick={(e) => addFunction(e)}>
          <Plus className='p4 rounded text-gray-600 hover:text-gray-900 hover:bg-gray-100' />
        </button>)
        }
      </div>
      <div>
        {children}
      </div>
    </div>
  );
};

export default DetailsPanel