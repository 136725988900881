import React, { useState } from "react";
import { toFlightId } from "../../../globalFunctions/FlightId";
import useFlightIdStore from "../../../stores/FlightIdStore";
import FlightCreationDetails from "../../createBooking/creationDetails/FlightCreationDetails";
import Flight from "../../../types/Flight";

const SearchFlight: React.FC = () =>{
    const { flightId, set } = useFlightIdStore();

    const setFlightId = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        set(event.target.value)
    }
    const [ flight, setFlight ] = useState<Flight>({
        CarrierCode: null,
        DepartureDate: null,
        Destination: null,
        FlightNumber: null,
        IncludeReturnFlight: null,
        Origin: null,
        Ssrs: []
    })

    const onFlightChange = (index: number, newFlight: Flight) => {
        setFlight(newFlight);
        var newFlightId = toFlightId(newFlight)
        if (newFlightId)
            set(newFlightId);
    }
    
    return (
        <div>
            <FlightCreationDetails
                index={0}
                flight={flight}
                onFlightChange={onFlightChange}
                onFlightDelete={onFlightChange}
                canDelete={false}
                canHaveReturnFlight={false}
                required={true} />
        </div>
    );
}

export default SearchFlight;